import React, {useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux"
import {I18N} from "aurelia-i18n"
import {Container} from "aurelia-dependency-injection"
import {Button, Checkbox, Popover, Stack, Table, Tooltip, Whisper} from "rsuite"
import moment from "moment-timezone"
import {CurrencyValueConverter} from "../../currency/currency-value-converter"
import {EntryDetails} from "../entry/details"
import {useAccountingEntriesQuery, useDeleteAccountingBookingMutation} from "../store/accounting-api"
import {selectCurrentPeriod} from "../store/state-slice"
import StatsLabel from "../../statistics/time-aggregation/stats-label"
import StateIcon from "./state-icon"
import {
    clear,
    selectClearable,
    selectCombinedCrDr,
    selectContext,
    selectExpandedBookings,
    selectOrganization,
    selectQueryParams,
    selectSelected,
    selectShowCleared,
    selectShowUncleared,
    selectSort,
    selectSum,
    toggleExpandedBooking,
    toggleSelect,
    toggleSelectAll,
    toggleSort,
    update
} from "../store/entries-slice"
import EditButton from "../entry/edit-button"
import {selectEditorDrawer} from "../store/editor-slice"
import confirm from "../../dialog/confirm"
import useAccountingStyles from "../styles";
import SioIcon from "../../icon/rsuite-icon-font/SioIcon";
import AureliaContext from "../../utilities/aurelia-context";

const i18n = Container.instance.get(I18N)
/** @type CurrencyValueConverter */
const currencyConverter = Container.instance.get(CurrencyValueConverter)

export default function EntriesTable() {
    const dispatch = useDispatch()
    const context = useSelector(selectContext)
    const organization = useSelector(selectOrganization)
    const period = useSelector(selectCurrentPeriod(organization))
    const expandedRowKeys = useSelector(selectExpandedBookings)
    const combinedCrDr = useSelector(selectCombinedCrDr)
    const params = useSelector(selectQueryParams)
    const [sortColumn, sortType] = useSelector(selectSort)
    const [crSum, drSum] = useSelector(selectSum)
    const canClear = 1 < useSelector(selectClearable).length
    const showCleared = useSelector(selectShowCleared)
    const showUncleared = useSelector(selectShowUncleared)
    const {isFetching: loading, data} = useAccountingEntriesQuery({context, organization, period, ...params})
    const {table} = useAccountingStyles()
    const {entries = [], amounts = [], currency, clearable = [], states = [], hasCleared, hasUncleared} = data ?? {}

    useEffect(() => () => {
        dispatch(clear())
    }, [])

    useEffect(
        () => {
            console.debug("update state")
            dispatch(update(amounts, currency, clearable, states, hasCleared, hasUncleared))
        }, [
            JSON.stringify(amounts),
            currency,
            clearable.length,
            states.length,
            hasCleared,
            hasUncleared
        ]
    )

    return (
        <Table
            virtualized
            loading={loading}
            data={(showCleared && showUncleared) ? entries :
                showUncleared ? entries.filter(({cleared}) => !cleared?.length) :
                    showCleared ? entries.filter(({cleared}) => !!cleared?.length) : []
            }
            fillHeight
            headerHeight={60}
            rowHeight={30}
            affixHorizontalScrollbar
            rowKey="booking"
            expandedRowKeys={expandedRowKeys}
            shouldUpdateScroll={false}
            rowExpandedHeight={250}
            className={table}
            sortColumn={sortColumn}
            sortType={sortType ? "asc" : "desc"}
            renderRowExpanded={booking => <EntryDetails booking={booking}/>}
            onSortColumn={column => dispatch(toggleSort(column))}
            wordWrap={false}
        >
            {canClear && (
                <Table.Column key="select" width={20} verticalAlign="top" fixed>
                    <Table.HeaderCell>
                        <span><SelectAll/></span>
                    </Table.HeaderCell>
                    <Table.Cell>
                        {({booking}) => <span><Selector booking={booking}/></span>}
                    </Table.Cell>
                </Table.Column>
            )}

            <Table.Column key="expand" width={20} verticalAlign="top" fixed>
                <Table.HeaderCell/>
                <Table.Cell>
                    {({booking}) => <ExpandButton id={booking}/>}
                </Table.Cell>
            </Table.Column>

            {"journal" !== context && (
                <Table.Column key="state" width={20} verticalAlign="top" fixed>
                    <Table.HeaderCell/>
                    <Table.Cell>
                        {({state, cleared}) => <StateIcon state={state} cleared={!!cleared?.length}/>}
                    </Table.Cell>
                </Table.Column>
            )}

            {"stack" !== context && !period && (
                <Table.Column key="period" width={60} align="right" verticalAlign="top" fixed>
                    <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.year')}</Table.HeaderCell>
                    <Table.Cell>
                        {({period: id, periodLabel: objectLabel}) => id && (
                            <StatsLabel label={{
                                id, objectLabel, modelId: "accounting/period", displayView: "accounting/period"
                            }}/>
                        )}
                    </Table.Cell>
                </Table.Column>
            )}

            {"stack" !== context && (
                <Table.Column key="bookSequence" width={60} align="right" verticalAlign="top" fixed sortable>
                    <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.bookSequence')}</Table.HeaderCell>
                    <Table.Cell dataKey="bookSequence"/>
                </Table.Column>
            )}

            <Table.Column key="bookDate" width={100} align="right" verticalAlign="top" fixed sortable>
                <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.bookDate')}</Table.HeaderCell>
                <Table.Cell dataKey="bookDate">
                    {({bookDate}) => bookDate && moment(bookDate).format("L")}
                </Table.Cell>
            </Table.Column>

            {"account" === context ? (
                <Table.Column key="contraAcount" width={60} align="right" verticalAlign="top" fixed sortable>
                    <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.contraAccount')}</Table.HeaderCell>
                    <Table.Cell>
                        {({drAccount, crAccount}) => <AccountLinks account={drAccount ?? crAccount}/>}
                    </Table.Cell>
                </Table.Column>
            ) : (
                <>
                    <Table.Column key="drAccount" width={60} align="right" verticalAlign="top" fixed sortable>
                        <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.drAccount')}</Table.HeaderCell>
                        <Table.Cell>
                            {({drAccount}) => <AccountLinks account={drAccount}/>}
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column key="crAccount" width={60} align="right" verticalAlign="top" fixed sortable>
                        <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.crAccount')}</Table.HeaderCell>
                        <Table.Cell>
                            {({crAccount}) => <AccountLinks account={crAccount}/>}
                        </Table.Cell>
                    </Table.Column>
                </>
            )}

            {"journal" === context ? (
                <Table.Column key="combinedCrDr" width={100} align="right" verticalAlign="top" fixed>
                    <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.amount')}</Table.HeaderCell>
                    <Table.Cell>
                        {({dr, cr}) => currencyConverter.toView(dr ?? cr)}
                    </Table.Cell>
                </Table.Column>
            ) : (
                combinedCrDr ? (
                    <Table.Column key="combinedCrDr" width={100} align="right" verticalAlign="top" fixed>
                        <Table.HeaderCell>
                            <HeaderWithSum
                                title="accounting.ledger-account.table.combinedCrDr"
                                amount={crSum - drSum}
                                currency={currency}
                                finance
                            />
                        </Table.HeaderCell>
                        <Table.Cell>
                            {({dr, cr}) => currencyConverter.toView({
                                amount: (cr?.amount ?? 0) - (dr?.amount ?? 0), currency
                            }, "finance")}
                        </Table.Cell>
                    </Table.Column>
                ) : (
                    <>
                        <Table.Column key="dr" width={100} align="right" verticalAlign="top" fixed>
                            <Table.HeaderCell>
                                <HeaderWithSum
                                    title="accounting.ledger-account.table.dr"
                                    amount={drSum}
                                    currency={currency}
                                />
                            </Table.HeaderCell>
                            <Table.Cell>{({dr}) => dr && currencyConverter.toView(dr)}</Table.Cell>
                        </Table.Column>

                        <Table.Column key="cr" width={100} align="right" verticalAlign="top" fixed>
                            <Table.HeaderCell>
                                <HeaderWithSum
                                    title="accounting.ledger-account.table.cr"
                                    amount={crSum}
                                    currency={currency}
                                />
                            </Table.HeaderCell>
                            <Table.Cell>{({cr}) => cr && currencyConverter.toView(cr)}</Table.Cell>
                        </Table.Column>
                    </>
                )
            )}

            {"account" === context && (
                <Table.Column key="balance" width={120} align="right" verticalAlign="top">
                    <Table.HeaderCell>
                        {i18n.tr('accounting.ledger-account.table.balance')}
                    </Table.HeaderCell>
                    <Table.Cell>
                        {({balance}) => balance?.currency && currencyConverter.toView(balance, "finance")}
                    </Table.Cell>
                </Table.Column>
            )}

            <Table.Column key="receiptDate" width={120} align="right" verticalAlign="top" sortable>
                <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.investmentDate')}</Table.HeaderCell>
                <Table.Cell
                    dataKey="receiptDate">{({receiptDate}) => receiptDate && moment(receiptDate).format("L")}</Table.Cell>
            </Table.Column>

            <Table.Column key="receiptNumber" width={100} align="right" verticalAlign="top" sortable>
                <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.voucherNumber')}</Table.HeaderCell>
                <Table.Cell dataKey="receiptNumber"/>
            </Table.Column>

            <Table.Column key="reference" width={120} align="left" verticalAlign="top">
                <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.reference')}</Table.HeaderCell>
                <Table.Cell>{({reference}) => reference && <StatsLabel label={reference}/>}</Table.Cell>
            </Table.Column>

            <Table.Column key="subject" verticalAlign="top" flexGrow={1}>
                <Table.HeaderCell>{i18n.tr('accounting.ledger-account.table.regarding')}</Table.HeaderCell>
                <Table.Cell>
                    {({subject}) => (
                        <Whisper
                            followCursor
                            arrow={false}
                            delayOpen={200}
                            placement="autoHorizontalStart"
                            speaker={<Popover style={{maxWidth: "75vw"}}>{subject}</Popover>}
                        >
                            <span>{subject}</span>
                        </Whisper>
                    )}
                </Table.Cell>
            </Table.Column>

            {"stack" === context && (
                <Table.Column key="actions" align="right">
                    <Table.HeaderCell/>
                    <Table.Cell>
                        {({booking}) => (
                            <>
                                <EditButton id={booking} small/>
                                <DeleteButton id={booking} small/>
                            </>
                        )}
                    </Table.Cell>
                </Table.Column>
            )}
        </Table>
    )
}

function HeaderWithSum({title, amount, currency, finance = false}) {
    const {i18n, currencyValueConverter} = useContext(AureliaContext)

    return (
        <Stack direction="column" alignItems="flex-end">
            <div>{i18n.tr(title)}</div>

            {currency && (
                <strong>
                    {currencyValueConverter.toView({amount, currency}, finance ? "finance" : undefined)}
                </strong>
            )}
        </Stack>
    )
}

function SelectAll() {
    const dispatch = useDispatch()
    const clearable = useSelector(selectClearable)
    const selected = useSelector(selectSelected)

    return (
        <Checkbox
            inline
            indeterminate={(0 < selected.length) && (selected.length < clearable.length)}
            checked={selected.length === clearable.length}
            onChange={() => dispatch(toggleSelectAll())}
        />
    )
}

function Selector({booking}) {
    const dispatch = useDispatch()
    const clearable = useSelector(selectClearable)
    const selected = useSelector(selectSelected)

    return (
        <Checkbox
            inline
            disabled={!clearable.includes(booking)}
            checked={selected.includes(booking)}
            onChange={() => dispatch(toggleSelect(booking))}
        />
    )
}

function ExpandButton({id}) {
    const dispatch = useDispatch()
    const expanded = useSelector(selectExpandedBookings)

    return (
        <SioIcon
            icon={(expanded.includes(id) ? "minus" : "plus") + "-square-o"}
            onClick={() => dispatch(toggleExpandedBooking(id))}
            style={{cursor: "pointer"}}
        />
        // <IconButton
        //     size="xs" appearance="link"
        //     onClick={() => dispatch(toggleExpandedBooking(id))}
        //     icon={<Icon icon={`${expanded.includes(id) ? "minus" : "plus"}-square-o`}/>}
        // />
    );
}

const AccountLinks = ({account}) => {
    if (!Array.isArray(account)) {
        return (
            <AccountLink id={account.id} code={account.code} name={account.name}/>
        )
    }

    return (
        <>
            <AccountLink id={account[0].id} code={account[0].code} name={account[0].name}/>
            <small style={{fontSize: "50%"}}>
                {account.map(({id, code, name}, idx) => 0 < idx && (
                    <React.Fragment key={id}>
                        <AccountLink id={id} code={code} name={name}/>
                        {" "}
                    </React.Fragment>
                ))}
            </small>
        </>
    )
}

const AccountLink = ({id, code, name}) => (
    <Whisper trigger="hover" placement="bottomEnd" speaker={<Tooltip>{name}</Tooltip>}>
        <div>
            <StatsLabel label={{
                id,
                modelId: "accounting/ledger-account",
                displayView: "accounting/ledger-account",
                objectLabel: code
            }}/>
        </div>
    </Whisper>
)

function DeleteButton({id}) {
    const [doDelete, {isLoading}] = useDeleteAccountingBookingMutation()
    const [open] = useSelector(selectEditorDrawer)

    function handleClick() {
        confirm("Buchung löschen", "Sind Sie sicher?").then(() => {
            doDelete(id)
        }, () => {
        })
    }

    return (
        <Button disabled={open} loading={isLoading} onClick={() => handleClick()} size="xs" appearance="default">
            <SioIcon icon="fa fa-trash"/>
        </Button>
    )
}
